const AbouttSectionForm = {
  label: 'About section',
  fields: [
    {
      label: 'Title',
      name: 'frontmatter.title',
      component: 'text'
    },
    {
      label: 'Contents',
      name: 'rawMarkdownBody',
      component: 'markdown'
    },
    {
      label: 'Claim',
      name: 'frontmatter.claim',
      component: 'text'
    }
  ]
}

export default AbouttSectionForm
