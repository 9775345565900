import React from 'react'

const ShopifyLogo = () => {
  return (
    <svg width='138' height='40' viewBox='0 0 138 40' fill='#0d5299' xmlns='http://www.w3.org/2000/svg'>
      <path d='M47.9116 22.1788C46.742 21.5324 46.1264 21.0092 46.1264 20.2705C46.1264 19.3163 46.9575 18.7315 48.281 18.7315C49.82 18.7315 51.1743 19.3778 51.1743 19.3778L52.2516 16.0844C52.2516 16.0844 51.2666 15.3149 48.3426 15.3149C44.2797 15.3149 41.4479 17.6542 41.4479 20.9168C41.4479 22.7636 42.7715 24.1795 44.5259 25.1952C45.9418 25.9955 46.4342 26.5803 46.4342 27.4113C46.4342 28.3039 45.7263 29.0119 44.4028 29.0119C42.4329 29.0119 40.5861 27.9961 40.5861 27.9961L39.4473 31.2895C39.4473 31.2895 41.1709 32.4284 44.0334 32.4284C48.2194 32.4284 51.2051 30.3662 51.2051 26.6726C51.2666 24.6412 49.7276 23.2253 47.9116 22.1788Z' />
      <path d='M64.5941 15.2526C62.5319 15.2526 60.9313 16.2375 59.6694 17.7149L59.6078 17.6841L61.393 8.32715H56.7145L52.1899 32.1198H56.8377L58.3766 23.994C58.9922 20.916 60.562 19.0384 62.0394 19.0384C63.0859 19.0384 63.486 19.7464 63.486 20.7621C63.486 21.4085 63.4245 22.178 63.2706 22.8243L61.5162 32.1505H66.1639L67.9799 22.5473C68.1953 21.5316 68.3184 20.3312 68.3184 19.5001C68.38 16.8223 67.0257 15.2526 64.5941 15.2526Z' />
      <path d='M78.9068 15.2534C73.3049 15.2534 69.5806 20.3013 69.5806 25.9339C69.5806 29.5351 71.7967 32.4592 75.9827 32.4592C81.4923 32.4592 85.1858 27.5345 85.1858 21.7787C85.2166 18.4237 83.2775 15.2534 78.9068 15.2534ZM76.6291 28.858C75.0285 28.858 74.3822 27.5037 74.3822 25.8108C74.3822 23.133 75.7673 18.7931 78.2912 18.7931C79.9533 18.7931 80.4765 20.2089 80.4765 21.594C80.4765 24.4873 79.0915 28.858 76.6291 28.858Z' />
      <path d='M97.1282 15.2534C93.9886 15.2534 92.2034 18.0236 92.2034 18.0236H92.1419L92.4189 15.5304H88.2944C88.079 17.2233 87.7096 19.778 87.3403 21.7171L84.1084 38.7075H88.7561L90.0489 31.8128H90.1412C90.1412 31.8128 91.0954 32.4284 92.8806 32.4284C98.3593 32.4284 101.93 26.8265 101.93 21.1631C101.93 18.0544 100.545 15.2534 97.1282 15.2534ZM92.6959 28.9503C91.4955 28.9503 90.7876 28.2732 90.7876 28.2732L91.5571 23.9333C92.1111 21.04 93.6193 19.1009 95.2198 19.1009C96.6357 19.1009 97.0666 20.4244 97.0666 21.6556C97.0666 24.6412 95.2814 28.9503 92.6959 28.9503Z' />
      <path d='M108.578 8.57324C107.101 8.57324 105.9 9.74287 105.9 11.2818C105.9 12.6669 106.762 13.6211 108.086 13.6211H108.147C109.594 13.6211 110.856 12.6361 110.887 10.9125C110.917 9.52741 109.994 8.57324 108.578 8.57324Z' />
      <path d='M102.053 32.1199H106.731L109.902 15.6221H105.192L102.053 32.1199Z' />
      <path d='M121.721 15.5913H118.489L118.643 14.8218C118.92 13.2213 119.844 11.8054 121.413 11.8054C122.244 11.8054 122.891 12.0517 122.891 12.0517L123.814 8.41967C123.814 8.41967 123.014 8.01953 121.29 8.01953C119.628 8.01953 117.997 8.48122 116.735 9.55851C115.134 10.9128 114.396 12.8519 114.026 14.8218L113.903 15.5913H111.749L111.071 19.1002H113.226L110.764 32.1199H115.411L117.874 19.1002H121.075L121.721 15.5913Z' />
      <path d='M132.925 15.6221C132.925 15.6221 130.001 22.9476 128.708 26.949H128.647C128.554 25.6562 127.508 15.6221 127.508 15.6221H122.614L125.415 30.7656C125.476 31.1042 125.445 31.3197 125.322 31.5351C124.768 32.5816 123.876 33.5973 122.798 34.3361C121.937 34.9824 120.952 35.3826 120.182 35.6596L121.475 39.5994C122.429 39.3839 124.368 38.6144 126.03 37.0754C128.154 35.0748 130.124 32.0276 132.125 27.8416L137.819 15.6221H132.925Z' />
      <path d='M23.4113 4.91093C23.4113 4.91093 22.9803 5.03405 22.2724 5.24951C22.1493 4.84938 21.9646 4.38768 21.7184 3.89521C20.9181 2.35623 19.7177 1.52518 18.3018 1.52518C18.2095 1.52518 18.1172 1.52518 17.994 1.55596C17.9633 1.4944 17.9017 1.46362 17.8709 1.40206C17.2553 0.724913 16.4551 0.417117 15.5009 0.447897C13.6541 0.509456 11.8074 1.83298 10.3299 4.203C9.28343 5.8651 8.48316 7.95811 8.2677 9.58943C6.14391 10.2358 4.66649 10.6975 4.63571 10.7283C3.55843 11.0668 3.52765 11.0976 3.40453 12.1134C3.31219 12.8828 0.480469 34.5517 0.480469 34.5517L23.7498 38.5838V4.88015C23.5651 4.88015 23.4728 4.91093 23.4113 4.91093ZM18.0248 6.57303C16.7936 6.94239 15.4393 7.3733 14.1158 7.77343C14.4852 6.32679 15.2239 4.88015 16.0857 3.92599C16.4243 3.58741 16.886 3.18728 17.4092 2.94104C17.9325 4.04911 18.0556 5.55731 18.0248 6.57303ZM15.5009 1.70986C15.9318 1.70986 16.3012 1.8022 16.609 1.98688C16.1165 2.23311 15.624 2.63325 15.1623 3.09494C13.9927 4.3569 13.1001 6.32679 12.7307 8.20435C11.6227 8.54292 10.5146 8.8815 9.49888 9.18929C10.176 6.23446 12.6692 1.77142 15.5009 1.70986ZM11.9305 18.6386C12.0536 20.6085 17.2553 21.0394 17.5631 25.6871C17.7786 29.3499 15.624 31.8431 12.5153 32.0277C8.76017 32.274 6.69794 30.0578 6.69794 30.0578L7.49821 26.6721C7.49821 26.6721 9.56044 28.2418 11.2225 28.1187C12.2998 28.0572 12.7 27.1646 12.6692 26.549C12.5153 23.9635 8.2677 24.1174 7.99068 19.8698C7.74445 16.2994 10.0837 12.6982 15.2547 12.3596C17.2553 12.2365 18.2711 12.7289 18.2711 12.7289L17.1014 17.1612C17.1014 17.1612 15.7779 16.5456 14.2082 16.6687C11.9305 16.8226 11.8997 18.2693 11.9305 18.6386ZM19.256 6.20368C19.256 5.28029 19.1329 3.95677 18.702 2.8487C20.1178 3.12572 20.795 4.69548 21.1028 5.64964C20.5487 5.80354 19.9332 5.98822 19.256 6.20368Z' />
      <path d='M24.458 38.5227L34.1228 36.1219C34.1228 36.1219 29.9676 8.02016 29.9368 7.83548C29.906 7.65081 29.7521 7.52769 29.5982 7.52769C29.4443 7.52769 26.7357 7.46613 26.7357 7.46613C26.7357 7.46613 25.0736 5.86559 24.458 5.25V38.5227Z' />
    </svg>
  )
}

export default ShopifyLogo
