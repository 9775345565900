import React from 'react'

const SlackLogo = () => {
  return (
    <svg width='124' height='32' viewBox='0 0 124 32' fill='#0d5299' xmlns='http://www.w3.org/2000/svg'>
      <path d='M40.0425 24.7698L41.5757 21.2081C43.2325 22.4448 45.4334 23.0879 47.6095 23.0879C49.2169 23.0879 50.2308 22.4696 50.2308 21.5297C50.206 18.9079 40.6112 20.9608 40.5371 14.3816C40.5123 11.0425 43.4798 8.47021 47.6837 8.47021C50.1813 8.47021 52.6789 9.08856 54.4594 10.4984L53.0251 14.1343C51.393 13.0954 49.3652 12.3534 47.4364 12.3534C46.1258 12.3534 45.2603 12.9718 45.2603 13.7633C45.285 16.3356 54.954 14.9257 55.0529 21.2081C55.0529 24.6214 52.1596 27.0206 48.0052 27.0206C44.9635 27.0206 42.1692 26.3033 40.0425 24.7698Z' />
      <path d='M98.8722 19.922C98.1056 21.2576 96.6713 22.1728 95.0145 22.1728C92.5664 22.1728 90.5881 20.1941 90.5881 17.7454C90.5881 15.2968 92.5664 13.318 95.0145 13.318C96.6713 13.318 98.1056 14.2332 98.8722 15.5688L103.101 13.2191C101.518 10.3995 98.4766 8.47021 95.0145 8.47021C89.8957 8.47021 85.7412 12.6255 85.7412 17.7454C85.7412 22.8653 89.8957 27.0206 95.0145 27.0206C98.5013 27.0206 101.518 25.1161 103.101 22.2717L98.8722 19.922Z' />
      <path d='M62.4716 0.777832H57.1797V26.6742H62.4716V0.777832Z' />
      <path d='M105.178 0.777832V26.6742H110.47V18.9077L116.751 26.6742H123.527L115.54 17.4484L122.934 8.84106H116.455L110.47 15.9891V0.777832H105.178Z' />
      <path d='M78.1992 19.9715C77.4326 21.2329 75.8499 22.1728 74.0695 22.1728C71.6213 22.1728 69.643 20.1941 69.643 17.7454C69.643 15.2968 71.6213 13.318 74.0695 13.318C75.8499 13.318 77.4326 14.3074 78.1992 15.5936V19.9715ZM78.1992 8.84122V10.9436C77.3337 9.4843 75.1822 8.47021 72.9319 8.47021C68.2829 8.47021 64.623 12.576 64.623 17.7207C64.623 22.8653 68.2829 27.0206 72.9319 27.0206C75.1822 27.0206 77.3337 26.0065 78.1992 24.5472V26.6496H83.4911V8.84122H78.1992Z' />
      <path d='M7.32626 19.947C7.32626 21.7526 5.86726 23.2119 4.06206 23.2119C2.25685 23.2119 0.797852 21.7526 0.797852 19.947C0.797852 18.1414 2.25685 16.6821 4.06206 16.6821H7.32626V19.947Z' />
      <path d='M8.9585 19.9465C8.9585 18.1409 10.4175 16.6816 12.2227 16.6816C14.0279 16.6816 15.4869 18.1409 15.4869 19.9465V28.1087C15.4869 29.9142 14.0279 31.3735 12.2227 31.3735C10.4175 31.3735 8.9585 29.9142 8.9585 28.1087V19.9465Z' />
      <path d='M12.2222 6.83784C10.417 6.83784 8.95801 5.37854 8.95801 3.57297C8.95801 1.7674 10.417 0.308105 12.2222 0.308105C14.0274 0.308105 15.4864 1.7674 15.4864 3.57297V6.83784H12.2222Z' />
      <path d='M12.2224 8.49463C14.0276 8.49463 15.4866 9.95393 15.4866 11.7595C15.4866 13.5651 14.0276 15.0244 12.2224 15.0244H4.03715C2.23195 15.0244 0.772949 13.5651 0.772949 11.7595C0.772949 9.95393 2.23195 8.49463 4.03715 8.49463H12.2224Z' />
      <path d='M25.3042 11.76C25.3042 9.95441 26.7632 8.49512 28.5684 8.49512C30.3736 8.49512 31.8326 9.95441 31.8326 11.76C31.8326 13.5656 30.3736 15.0248 28.5684 15.0248H25.3042V11.76Z' />
      <path d='M23.672 11.7599C23.672 13.5654 22.213 15.0247 20.4078 15.0247C18.6026 15.0247 17.1436 13.5654 17.1436 11.7599V3.57297C17.1436 1.7674 18.6026 0.308105 20.4078 0.308105C22.213 0.308105 23.672 1.7674 23.672 3.57297V11.7599Z' />
      <path d='M20.4078 24.8442C22.213 24.8442 23.672 26.3035 23.672 28.1091C23.672 29.9147 22.213 31.374 20.4078 31.374C18.6026 31.374 17.1436 29.9147 17.1436 28.1091V24.8442H20.4078Z' />
      <path d='M20.4078 23.2119C18.6026 23.2119 17.1436 21.7526 17.1436 19.947C17.1436 18.1414 18.6026 16.6821 20.4078 16.6821H28.593C30.3982 16.6821 31.8572 18.1414 31.8572 19.947C31.8572 21.7526 30.3982 23.2119 28.593 23.2119H20.4078Z' />
    </svg>
  )
}

export default SlackLogo
