const FocusBlockForm = {
  fields: [
    {
      label: 'Badge label',
      name: 'frontmatter.badgeLabel',
      component: 'text'
    },
    {
      label: 'Block title',
      name: 'frontmatter.title',
      component: 'text'
    },
    {
      label: 'Subheading',
      name: 'frontmatter.subHeading',
      component: 'text'
    },
    {
      label: 'Body text',
      name: 'frontmatter.bodyText',
      component: 'textarea'
    },
    {
      label: 'Feature list title',
      name: 'frontmatter.listTitle',
      component: 'text'
    },
    {
      label: 'Feature list items',
      name: 'rawMarkdownBody',
      component: 'markdown'
    }
  ]
}

export default FocusBlockForm
