import React from 'react'
import { useLocalRemarkForm } from 'gatsby-tinacms-remark'
import { useStaticQuery, graphql } from 'gatsby'
import HeroSectionForm from './form'
import PrimaryTitle from '../PrimaryTitle'
import SubHeading from '../SubHeading'
import Button from '../Button'
import scroller from '../../utils/scroller'

import {
  HeroContainer,
  ContentsContainer,
  IllustrationContainer,
  ButtonContainer,
  SubHeadingContainer
} from './styles'

const HeroSection = () => {
  // get the data
  const data = useStaticQuery(graphql`
    query TitleQuery {
      markdownRemark(frontmatter: {blockId: {eq: "home-hero"}}) {
        ...TinaRemark
        frontmatter {
          title,
          subHeading,
          buttonLabel
        }
      }
    }
  `)
  // tina form
  const [markdownRemark] = useLocalRemarkForm(data.markdownRemark, HeroSectionForm)

  return (
    <HeroContainer>
      <ContentsContainer>
        <PrimaryTitle>
          {markdownRemark.frontmatter.title}
        </PrimaryTitle>
        <SubHeadingContainer>
          <SubHeading>
            {markdownRemark.frontmatter.subHeading}
          </SubHeading>
        </SubHeadingContainer>
        <ButtonContainer>
          <Button primary onClick={() => { scroller('focus', 50) }}>
            {markdownRemark.frontmatter.buttonLabel}
          </Button>
        </ButtonContainer>
      </ContentsContainer>
      <IllustrationContainer />
    </HeroContainer>
  )
}

export default HeroSection
