import React from 'react'
import PropTypes from 'prop-types'

import AWSLogo from '../AWSLogo'
import MailchimpLogo from '../MailchimpLogo'
import SlackLogo from '../SlackLogo'
import GSuiteLogo from '../GSuiteLogo'
import ShopifyLogo from '../ShopifyLogo'
import TypeformLogo from '../TypeformLogo'

import {
  LogosContainer,
  LogosCaption,
  Logos,
  LogoWrapper
} from './styles'

const PLGLogosBlock = ({ logosCaption }) => (
  <LogosContainer>
    <LogosCaption>
      {logosCaption}
    </LogosCaption>
    <Logos>
      <LogoWrapper><AWSLogo /></LogoWrapper>
      <LogoWrapper><MailchimpLogo /></LogoWrapper>
      <LogoWrapper><SlackLogo /></LogoWrapper>
      <LogoWrapper><GSuiteLogo /></LogoWrapper>
      <LogoWrapper><ShopifyLogo /></LogoWrapper>
      <LogoWrapper><TypeformLogo /></LogoWrapper>
    </Logos>
  </LogosContainer>
)

PLGLogosBlock.propTypes = {
  logosCaption: PropTypes.string
}

export default PLGLogosBlock
