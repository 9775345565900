const HeroSectionForm = {
  label: 'Hero section',
  fields: [
    {
      label: 'Heading',
      name: 'frontmatter.title',
      description: 'Keep it short',
      component: 'text'
    },
    {
      label: 'Subheading',
      name: 'frontmatter.subHeading',
      description: 'Maximum 4 lines, please',
      component: 'textarea'
    },
    {
      label: 'CTA button',
      name: 'frontmatter.buttonLabel',
      component: 'text'
    }
  ]
}

export default HeroSectionForm
