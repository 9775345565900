import React from 'react'

const TypeformLogo = () => {
  return (
    <svg width='130' height='73' viewBox='0 0 145 81' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path d='M42.3208 79.8881C54.6017 80.6907 64.7517 77.1567 73.201 69.8925C81.6465 62.6319 86.4245 53.6411 86.199 41.9492C85.9735 30.2235 79.9026 18.6529 72.007 10.7884C64.1521 2.96413 56.8814 1.22458 45.2657 1.01587C33.6507 0.80716 21.8716 2.60079 13.3221 9.74327C4.7308 16.927 1.26445 27.4122 0.858519 38.5448C0.452585 49.6166 3.24072 57.8906 11.1726 65.8278C19.1427 73.8035 30.0135 79.0856 42.3208 79.8881Z' stroke='#0d5299' />
      <path d='M40.4961 31.8574V29.4019H53.5244V31.8574H48.3092V45.9313H45.7114V31.8574H40.4961Z' fill='#0d5299' />
      <path d='M67.4802 34.0303H64.6225L60.5684 42.7517L56.7456 34.0303H53.916L59.1122 45.8844L56.8506 50.7483H59.7088L67.4802 34.0303Z' fill='#0d5299' />
      <path d='M78.1572 39.9805C78.1572 38.7761 77.8742 37.8079 77.3077 37.1002C76.7407 36.3678 76.0088 36.0134 75.111 36.0134C74.2137 36.0134 73.4818 36.3678 72.9153 37.1002C72.3483 37.8079 72.0653 38.7761 72.0653 39.9805C72.0653 41.1849 72.3483 42.1526 72.9153 42.885C73.4818 43.5932 74.2137 43.9476 75.111 43.9476C76.0088 43.9476 76.7407 43.5932 77.3077 42.885C77.8742 42.1526 78.1572 41.1849 78.1572 39.9805ZM75.3945 46.1202C74.0011 46.1202 72.7972 45.4587 72.2071 44.6091V50.7478H69.751V34.0303H72.2071V35.3996C72.6559 34.62 74.0011 33.8408 75.4423 33.8408C78.9132 33.8408 80.755 36.1074 80.755 39.9805C80.755 43.7586 78.8891 46.1202 75.3945 46.1202Z' fill='#0d5299' />
      <path d='M85.5372 38.6112H91.3225C91.1098 37.1233 90.1181 36.0139 88.4416 36.0139C86.8597 36.0139 85.8202 37.076 85.5372 38.6112ZM93.9198 40.6651H85.4894C85.4894 42.5311 86.5757 43.9476 88.4416 43.9476C89.7637 43.9476 90.9686 43.1916 91.5587 41.9404L93.6599 42.7668C92.456 45.1043 91.0626 46.1202 88.4416 46.1202C84.5218 46.1202 82.8921 42.8141 82.8921 39.9805C82.8921 37.1464 84.5218 33.8408 88.4416 33.8408C92.2434 33.8408 93.9198 36.9343 93.9198 39.603V40.6651Z' fill='#0d5299' />
      <path d='M95.9219 36.2021V34.03H97.457V33.4163C97.457 30.6058 98.9686 29.2129 101.447 29.2129C102.226 29.2129 102.865 29.3541 103.408 29.6377L102.911 31.7157C102.487 31.5031 102.014 31.385 101.518 31.385C100.456 31.385 99.9126 31.9992 99.9126 33.2509V34.03H102.534V36.2021H99.9126V45.9309H97.457V36.2021H95.9219Z' fill='#0d5299' />
      <path d='M106.757 39.9805C106.757 41.0668 107.04 42.0113 107.606 42.7905C108.197 43.5701 108.999 43.9476 110.015 43.9476C111.03 43.9476 111.834 43.5701 112.4 42.7905C112.99 42.0113 113.274 41.0668 113.274 39.9805C113.274 38.8942 112.99 37.9733 112.4 37.1942C111.834 36.4145 111.03 36.0134 110.015 36.0134C108.999 36.0134 108.197 36.4145 107.606 37.1942C107.04 37.9733 106.757 38.8942 106.757 39.9805ZM104.159 39.9805C104.159 38.2568 104.678 36.793 105.741 35.6122C106.804 34.431 108.244 33.8408 110.015 33.8408C111.786 33.8408 113.203 34.431 114.265 35.6122C115.328 36.793 115.871 38.2568 115.871 39.9805C115.871 41.7042 115.328 43.1685 114.265 44.3493C113.203 45.5295 111.786 46.1202 110.015 46.1202C108.244 46.1202 106.804 45.5295 105.741 44.3493C104.678 43.1685 104.159 41.7042 104.159 39.9805Z' fill='#0d5299' />
      <path d='M120.66 45.9312H118.204V34.0303H120.66V36.0139C121.439 34.6205 122.856 33.8408 124.178 33.8408C124.721 33.8408 125.217 33.9358 125.689 34.1243L125.264 36.3678C124.887 36.1315 124.415 36.0139 123.871 36.0139C122.998 36.0139 122.242 36.4859 121.605 37.4535C120.967 38.3981 120.66 39.7442 120.66 41.5152V45.9312Z' fill='#0d5299' />
      <path d='M128.215 34.0303H130.67V36.0611C131.639 34.5732 132.819 33.8413 134.26 33.8413C135.866 33.8413 137.188 34.8095 137.542 36.2969C138.368 34.715 139.715 33.8413 141.25 33.8413C143.398 33.8413 144.65 35.2578 144.65 37.4068V45.9312H142.194V37.9502C142.194 36.6985 141.509 36.0139 140.446 36.0139C138.912 36.0139 137.66 38.0206 137.66 41.1613V45.9312H135.204V37.9502C135.204 36.6985 134.519 36.0139 133.457 36.0139C131.875 36.0139 130.67 38.0206 130.67 41.4212V45.9312H128.215V34.0303Z' fill='#0d5299' />
    </svg>
  )
}

export default TypeformLogo
