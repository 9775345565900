import React from 'react'
import PropTypes from 'prop-types'

import TabLink from '../TabLink'
import TabUnderline from '../TabUnderline'
import mapIndexed from '../../utils/mapIndexed'
import scroller from '../../utils/scroller'

import {
  StyledTabbedNavContainer,
  StyledScrollableTabsContainer,
  StyledTabsFade,
  StyledTabsList
} from './styles'

const TabbedNavContainer = ({
  onTouchStart,
  onMouseOver,
  canTouchThis,
  activeSection,
  tabs
}) => {
  const tab = (val, key) => (
    <li key={key + ''}>
      <TabLink
        canTouchThis={canTouchThis}
        activeTab={activeSection === val.to}
        onClick={() => { scroller(val.to, 50) }}
      >
        {val.name}
        {activeSection === val.to && <TabUnderline />}
      </TabLink>
    </li>
  )
  const tabsList = mapIndexed(tab, tabs)
  return (
    <StyledTabbedNavContainer
      onTouchStart={onTouchStart}
      onMouseOver={onMouseOver}
    >
      <StyledTabsFade />
      <StyledScrollableTabsContainer>
        <StyledTabsList>
          {tabsList}
        </StyledTabsList>
      </StyledScrollableTabsContainer>
    </StyledTabbedNavContainer>
  )
}
TabbedNavContainer.propTypes = {
  canTouchThis: PropTypes.bool,
  activeSection: PropTypes.string,
  tabs: PropTypes.array.isRequired,
  onTouchStart: PropTypes.func.isRequired,
  onMouseOver: PropTypes.func.isRequired
}

export default TabbedNavContainer
