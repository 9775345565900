import React from 'react'

import StyledHomeNavContainer from './styles'

const HomeNavContainer = React.forwardRef((props, ref) => (
  <StyledHomeNavContainer ref={ref} {...props} />
))

HomeNavContainer.displayName = 'HomeNavContainer'

export default HomeNavContainer
