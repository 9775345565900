import styled from '@emotion/styled'
import SecondaryTitle from '../SecondaryTitle'
import Paragraph from '../Paragraph'
import TertiaryTitle from '../TertiaryTitle'

const ContactContainer = styled.div`
  width: 100%;
  display: flex;
  flex-flow: row wrap;
  align-items: space-between;
  justify-content: center;
  padding: 4rem 0;

  @media screen and (min-width: 52rem)
  {
    padding: 5rem 0;
  }
`

const ContactStep = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  flex-flow: column wrap;
  padding: 3rem 0;

  @media screen and (min-width: 37.5rem)
  {
    width: 60%;
  }

  @media screen and (min-width: 60rem)
  {
    width: 28.333%;
  }
`

const ActionStep = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  @media screen and (min-width: 37.5rem)
  {
    width: 60%;
  }

  @media screen and (min-width: 60rem)
  {
    width: 7.5%;
  }
`

const CenteredSecondaryTitle = styled(SecondaryTitle)`
  color: #fff;
  width: 100%;
  text-align: center;
`

const CenteredParagraph = styled(Paragraph)`
  color: #0c4887;
  width: 100%;
  text-align: center;
  margin: 0 0 1rem 0;
`

const CenteredParagraphStep = styled(CenteredParagraph)`
  color: #000;
  width: 100%;
  text-align: center;
  margin: 1rem 0;
`

const SendEmailParagraph = styled(CenteredParagraph)`
  font-weight: 700;
`

const CenteredTertiaryTitle = styled(TertiaryTitle)`
  color: #fff;
  width: 100%;
  text-align: center;
`

const StepTitle = styled(CenteredTertiaryTitle)`
  font-weight: 400;
  font-style: italic;
`

const AvailabilityNote = styled.span`
  color: #fff500;
  font-size: 1rem;
  font-style: italic;
  width: 100%;
  text-align: center;
  line-height: 1.5;
`

const ContactButtonContainer = styled.div`
  width: 100%;
  padding: 2rem 0 1rem 0;
  display: flex;
  justify-content: center;
`

export {
  ContactContainer,
  CenteredSecondaryTitle,
  CenteredTertiaryTitle,
  StepTitle,
  CenteredParagraph,
  CenteredParagraphStep,
  ContactStep,
  ActionStep,
  AvailabilityNote,
  ContactButtonContainer,
  SendEmailParagraph
}
