import styled from '@emotion/styled'
import { keyframes } from '@emotion/core'

const slideNBounce = keyframes`
  0%
  {
    transform: translateY(100%);
  }

  80%
  {
    transform: translateY(-20%);
  }

  100%
  {
    transform: translateY(0%);
  }
`

export default styled.span`
  position: absolute;
  pointer-events: none;
  height: 0.25rem;
  width: 100%;
  border-radius: 0.25rem 0.25rem 0 0;
  background-color: #0085ff;
  bottom: 0;
  left: 0;
  animation-name: ${slideNBounce};
  animation-duration: 0.2s;
  animation-timing-function: ease-in-out;
`
