import { useState, useEffect } from 'react'
import throttle from 'lodash.throttle'

export default ({
  activeSectionDefault = null,
  offsetPx = 0,
  sectionElementRefs = [],
  throttleMs = 100
}) => {
  const [activeSection, setActiveSection] = useState(activeSectionDefault)
  const [firstRun, setFirstRun] = useState(false)
  const handle = throttle(() => {
    // Assign default non-existing section id
    let currentSectionId = activeSectionDefault
    for (let i = 0; i < sectionElementRefs.length; i++) {
      const section = sectionElementRefs[i].current
      // Needs to be a valid DOM Element
      if (!section || !(section instanceof Element)) continue
      // Element is below / above the viewport, skip
      if (section.getBoundingClientRect().top + offsetPx > 0 ||
          section.getBoundingClientRect().top +
          offsetPx +
          section.getBoundingClientRect().height < 0) {
        continue
      }
      // Assign current element id
      currentSectionId = section.id
      break
    }
    setActiveSection(currentSectionId)
  }, throttleMs)

  useEffect(() => {
    if (!firstRun) {
      handle()
      setFirstRun(true)
    }
    window.addEventListener('scroll', handle)
    return () => {
      window.removeEventListener('scroll', handle)
    }
  }, [offsetPx])
  return activeSection
}
