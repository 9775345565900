import React from 'react'
import HeroSection from '../HeroSection'
import Header from '../Header'
import Layout from '../Layout'

import LayoutWithBackground from '../LayoutWithBackground'

const HomeHeader = () =>
  <LayoutWithBackground as='header' noTop>
    <Layout>
      <Header withContact />
      <HeroSection />
    </Layout>
  </LayoutWithBackground>

export default HomeHeader
