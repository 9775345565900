import React from 'react'
import PropTypes from 'prop-types'

import StyledBadge from './styles'

const InlineBadge = (props) => (
  <StyledBadge {...props} />
)

InlineBadge.propTypes = {
  bg: PropTypes.string,
  color: PropTypes.string
}

InlineBadge.defaultProps = {
  bg: '#0085ff',
  color: '#fff'
}

export default InlineBadge
