import styled from '@emotion/styled'
import SecondaryTitle from '../SecondaryTitle'
import TertiaryTitle from '../TertiaryTitle'
import QuaternaryTitle from '../QuaternaryTitle'

const ServicesContainer = styled.div`
  width: 100%;
  display: flex;
  flex-flow: row wrap;
  align-items: space-between;
  justify-content: center;
  padding: 4rem 0 0 0;

  @media screen and (min-width: 52rem)
  {
    padding: 6rem 0 0 0;
  }
`
const ServicesBadge = styled.div`
  width: 100%;
  text-align: center;
`

const ServicesTitle = styled(SecondaryTitle)`
  color: #fff;
  width: 100%;
  text-align: center;

  @media screen and (min-width: 52rem)
  {
    width: 80%;
  }

  @media screen and (min-width: 65rem)
  {
    width: 60%;
  }
`

const ServicesTertiaryTitle = styled(TertiaryTitle)`
  color: #fff;
`

const ServicesQuaternaryTitle = styled(QuaternaryTitle)`
  color: #fff;
`

const ServiceTile = styled.div`
  display: flex;
  flex-flow: column nowrap;
  justify-content: space-between;
  width: 100%;
  box-shadow: 0 12px 40px rgba(8, 61, 119, 0.33);
  border-radius: 0.875rem;
  background-color: #fff;
  padding: 1.5rem;
  margin: 1.5rem 0;

  @media screen and (min-width: 52rem)
  {
    border-radius: 1.125rem;
    width: 45%;
    padding: 2.5rem;
    margin: 2.5rem 1rem;
  }

  @media screen and (min-width: 65rem)
  {
    width: 42%;
  }
`

const TileButtonContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
`

const TileListContainer = styled.div`
  padding: 1.125rem 0 1.625rem 0;
`

const TrainingsContainer = styled.div`
  width: 100%;
  display: flex;
  flex-flow: row wrap;
  padding: 1rem 0 0 0;
  justify-content: space-between;

  @media screen and (min-width: 52rem)
  {
    width: 89%;
  }

  @media screen and (min-width: 65rem)
  {
    width: 83%;
  }
`

const TrainingTile = styled.div`
  width: 100%;
  padding: 2rem 0 0.5rem 0;

  @media screen and (min-width: 52rem)
  {
    width: 48%;
  }
`

const FullWidthWrapper = styled.div`
  width: 100%;
`

export {
  ServicesBadge,
  ServicesContainer,
  ServicesTitle,
  ServicesTertiaryTitle,
  ServicesQuaternaryTitle,
  ServiceTile,
  TileButtonContainer,
  TileListContainer,
  TrainingsContainer,
  TrainingTile,
  FullWidthWrapper
}
