import React from 'react'

const GSuiteLogo = () => {
  return (
    <svg width='108' height='28' viewBox='0 0 108 28' fill='#0d5299' xmlns='http://www.w3.org/2000/svg'>
      <path d='M20.1851 8.24399C19.3532 7.394 18.449 6.74294 17.4905 6.29082C16.532 5.8387 15.3203 5.62168 13.9277 5.62168C12.8065 5.62168 11.7395 5.82061 10.7448 6.21848C9.75013 6.61635 8.88206 7.17698 8.14058 7.90037C7.38102 8.62377 6.78422 9.50993 6.35018 10.5408C5.91614 11.5716 5.69912 12.7109 5.69912 13.9769C5.69912 15.2428 5.91614 16.3822 6.35018 17.413C6.78422 18.4438 7.38102 19.3119 8.14058 20.0353C8.90014 20.7587 9.7863 21.3374 10.781 21.7353C11.7756 22.1332 12.8607 22.3321 14.0001 22.3321C15.2118 22.3321 16.2788 22.1693 17.183 21.8257C18.0873 21.4821 18.8649 21.0119 19.516 20.4332C19.95 20.0353 20.3479 19.547 20.6915 18.9683C21.0351 18.3715 21.3064 17.7205 21.5053 16.9971H13.8554V12.6567H26.3339C26.4063 12.946 26.4605 13.2897 26.5148 13.7237C26.5691 14.1577 26.5871 14.5556 26.5871 14.9716C26.5871 16.6896 26.334 18.2449 25.8276 19.6736C25.3212 21.1023 24.5435 22.3502 23.5308 23.4534C22.3914 24.665 21.0351 25.5874 19.4436 26.2565C17.8522 26.9256 16.0256 27.2512 13.982 27.2512C12.1373 27.2512 10.4012 26.9256 8.77355 26.2565C7.14591 25.5874 5.71721 24.665 4.48744 23.4895C3.27575 22.314 2.31726 20.9215 1.59386 19.3119C0.870468 17.7024 0.526855 15.912 0.526855 13.995C0.526855 12.078 0.888553 10.3057 1.59386 8.67802C2.29917 7.05039 3.25767 5.65785 4.48744 4.48234C5.69912 3.30682 7.12783 2.40258 8.77355 1.73344C10.4193 1.0643 12.1554 0.73877 13.982 0.73877C16.0798 0.73877 17.9426 1.10047 19.5521 1.82386C21.1617 2.54726 22.5542 3.54192 23.7297 4.80786L20.1851 8.24399Z' />
      <path d='M52.2497 19.8906C52.2497 22.1151 51.4358 23.9055 49.8082 25.2618C48.1625 26.582 46.155 27.2512 43.7859 27.2512C41.6881 27.2512 39.8254 26.6363 38.2158 25.4065C36.6062 24.1767 35.5031 22.4948 34.8882 20.3789L37.9988 19.0949C38.2158 19.8544 38.5052 20.5417 38.8849 21.1566C39.2647 21.7714 39.6988 22.2959 40.2051 22.7299C40.7115 23.164 41.2721 23.5076 41.887 23.7608C42.5019 24.014 43.153 24.1406 43.8402 24.1406C45.3231 24.1406 46.5529 23.7608 47.4933 22.9831C48.4337 22.2236 48.904 21.1927 48.904 19.9268C48.904 18.8598 48.5061 17.9555 47.7284 17.196C47.005 16.4726 45.6306 15.7492 43.6232 15.062C41.5977 14.3386 40.3317 13.8322 39.8254 13.579C37.1307 12.2046 35.7924 10.1971 35.7924 7.52057C35.7924 5.65783 36.5339 4.06636 38.0169 2.74617C39.536 1.42598 41.3806 0.756836 43.587 0.756836C45.5221 0.756836 47.204 1.24513 48.6146 2.23979C50.0252 3.21638 50.9837 4.42806 51.4539 5.89294L48.4337 7.14079C48.1444 6.20038 47.5838 5.40464 46.7518 4.78976C45.9199 4.15679 44.8891 3.84935 43.6955 3.84935C42.4115 3.84935 41.3445 4.21104 40.4764 4.91635C39.6083 5.56741 39.1743 6.43548 39.1743 7.4844C39.1743 8.35248 39.5179 9.11204 40.2051 9.74501C40.9647 10.378 42.6104 11.1375 45.1242 12.0056C47.6923 12.8737 49.5369 13.9588 50.6401 15.2247C51.7071 16.4907 52.2497 18.046 52.2497 19.8906Z' />
      <path d='M70.3164 26.6725H67.2058V24.2672H67.0612C66.5729 25.1172 65.7952 25.8225 64.7825 26.4012C63.7516 26.9619 62.6846 27.2512 61.5815 27.2512C59.4474 27.2512 57.8198 26.6363 56.6805 25.4246C55.5411 24.213 54.9624 22.4768 54.9624 20.2343V9.31104H58.2177V20.0173C58.29 22.8566 59.7187 24.2672 62.5038 24.2672C63.8059 24.2672 64.891 23.7428 65.7591 22.6938C66.6271 21.6449 67.0612 20.379 67.0612 18.9141V9.31104H70.3164V26.6725Z' />
      <path d='M77.7493 3.32461C77.7493 3.95758 77.5323 4.50012 77.0802 4.95225C76.6281 5.40437 76.0855 5.62139 75.4525 5.62139C74.8196 5.62139 74.277 5.40437 73.8249 4.95225C73.3728 4.50012 73.1558 3.95758 73.1558 3.32461C73.1558 2.69164 73.3728 2.14909 73.8249 1.69697C74.277 1.24485 74.8196 1.02783 75.4525 1.02783C76.0855 1.02783 76.6281 1.24485 77.0802 1.69697C77.5323 2.14909 77.7493 2.69164 77.7493 3.32461ZM77.0802 9.3107V26.6722H73.8249V9.3107H77.0802Z' />
      <path d='M87.0809 26.9614C85.6703 26.9614 84.4948 26.5274 83.5544 25.6593C82.614 24.7912 82.1438 23.5615 82.1257 22.0062V12.2946H79.0693V9.3106H82.1076V3.99365H85.3629V9.3106H89.6128V12.2946H85.3629V20.9392C85.3629 22.0966 85.5799 22.8742 86.032 23.2902C86.4841 23.7061 86.9905 23.9051 87.5511 23.9051C87.8043 23.9051 88.0575 23.8689 88.3107 23.8147C88.5639 23.7604 88.7809 23.67 88.9979 23.5796L90.0288 26.4912C89.2149 26.7987 88.2203 26.9614 87.0809 26.9614Z' />
      <path d='M99.2702 27.2322C96.7202 27.2322 94.6224 26.3642 92.9586 24.6099C91.3129 22.8557 90.481 20.6493 90.481 17.9909C90.481 15.3505 91.2767 13.1441 92.8862 11.3899C94.4958 9.63567 96.5394 8.74951 99.0532 8.74951C101.621 8.74951 103.683 9.58142 105.202 11.2452C106.721 12.909 107.481 15.242 107.481 18.2441L107.445 18.6058H93.8086C93.8628 20.3057 94.4235 21.6802 95.5086 22.711C96.5936 23.7419 97.8957 24.2663 99.4149 24.2663C101.495 24.2663 103.122 23.2355 104.298 21.1557L107.209 22.5663C106.432 24.0312 105.347 25.1706 103.972 26.0025C102.58 26.8163 101.006 27.2322 99.2702 27.2322ZM94.0618 15.893H104.027C103.936 14.6813 103.448 13.6867 102.562 12.8909C101.675 12.0952 100.482 11.6973 98.9989 11.6973C97.7692 11.6973 96.7202 12.0771 95.8341 12.8367C94.9298 13.6143 94.3511 14.6271 94.0618 15.893Z' />
    </svg>
  )
}

export default GSuiteLogo
