import styled from '@emotion/styled'

const StyledTabbedNavContainer = styled.div`
  text-align: center;
  background-color: #fff;
  height: 4rem;
  overflow: hidden;
  position: relative;
  width: 100%;
`

const StyledTabsFade = styled.div`
  background-image: linear-gradient(270deg, rgba(255, 255, 255, 1), rgba(255, 255, 255, 0));
  height: 4rem;
  position: absolute;
  pointer-events: none;
  right: 0;
  top: 0;
  width: 2rem;
  z-index: 2;
`

const StyledScrollableTabsContainer = styled.div`
  display: block;
  height: 5.25rem;
  padding: 0;
  margin: 0;
  position: relative;
  overflow-y: hidden;
  overflow-x: scroll;
  user-select: none;
`

const StyledTabsList = styled.ul`
  font-size: 1rem;
  list-style: none outside none;
  margin: 0;
  padding: 0 1rem;
  display: inline-block;
  overflow: hidden;
  position: relative;
  white-space: nowrap;

  > li
  {
    display: inline-block;
  }
`

export {
  StyledTabbedNavContainer,
  StyledScrollableTabsContainer,
  StyledTabsFade,
  StyledTabsList
}
