import React from 'react'
import PropTypes from 'prop-types'

import {
  CharCircle,
  Char
} from './styles'

const CharacterCircle = ({ children }) => {
  return (
    <CharCircle>
      <Char>
        {children}
      </Char>
    </CharCircle>
  )
}

CharacterCircle.propTypes = {
  children: PropTypes.node.isRequired
}

export default CharacterCircle
