const FeaturedWorkForm = {
  label: 'Featured work',
  fields: [
    {
      label: 'Block title',
      name: 'frontmatter.title',
      component: 'text'
    },
    {
      label: 'Badge label',
      name: 'frontmatter.badgeLabel',
      component: 'text'
    },
    {
      label: 'Subheading',
      name: 'frontmatter.subHeading',
      component: 'text'
    },
    {
      label: 'Role',
      name: 'frontmatter.role',
      component: 'text'
    },
    {
      label: 'Achievements',
      name: 'rawMarkdownBody',
      component: 'markdown'
    }
  ]
}

export default FeaturedWorkForm
