import React from 'react'
import { useLocalRemarkForm } from 'gatsby-tinacms-remark'
import { useStaticQuery, graphql } from 'gatsby'

import InlineBadge from '../InlineBadge'
import Paragraph from '../Paragraph'
import ListTitle from '../ListTitle'
import Button from '../Button'
import TertiaryTitle from '../TertiaryTitle'

import scroller from '../../utils/scroller'
import renderAst from '../../utils/renderAst'
import ServicesBlockForm from '../ProductConsultingTile/form'
import {
  ServiceTile,
  TileButtonContainer,
  TileListContainer
} from '../ServicesSection/styles'

const ServicesProductOwner = () => {
  // get the data
  const data = useStaticQuery(graphql`
    query ServicesProductOwnerQuery {
      markdownRemark(frontmatter: {blockId: {eq: "services-product-owner"}}) {
        ...TinaRemark
        frontmatter {
          title
          badgeLabel
          bodyText
          listTitle
          summaryTitle
          summaryBody
          buttonLabel
        }
        htmlAst
      }
    }
  `)
  // tina form
  const [markdownRemark] = useLocalRemarkForm(data.markdownRemark, ServicesBlockForm)
  return (
    <ServiceTile>
      <TertiaryTitle>
        {markdownRemark.frontmatter.title}
      </TertiaryTitle>
      <div>
        <InlineBadge>
          {markdownRemark.frontmatter.badgeLabel}
        </InlineBadge>
      </div>
      <Paragraph>
        {markdownRemark.frontmatter.bodyText}
      </Paragraph>
      <TileListContainer>
        <ListTitle>
          {markdownRemark.frontmatter.listTitle}
        </ListTitle>
        {renderAst(markdownRemark.htmlAst)}
      </TileListContainer>
      <ListTitle>
        {markdownRemark.frontmatter.summaryTitle}
      </ListTitle>
      <Paragraph>
        {markdownRemark.frontmatter.summaryBody}
      </Paragraph>
      <TileButtonContainer>
        <Button primary onClick={() => { scroller('contact', 50) }}>
          {markdownRemark.frontmatter.buttonLabel}
        </Button>
      </TileButtonContainer>
    </ServiceTile>
  )
}

export default ServicesProductOwner
