import styled from '@emotion/styled'

export default styled.a`
  display: block;
  font-size: 1rem;
  height: 4rem;
  padding: 1.5rem 0.5rem 1.625rem 0.5rem;
  margin: 0 0.375rem;
  color:
    ${props => props.activeTab
    ? '#0085ff'
    : '#798b9e'};
  text-decoration: none;
  position: relative;
  cursor: pointer;

  :hover
  {
    color:
      ${props => props.activeTab
      ? '#0085ff'
      : props.canTouchThis
      ? '#798b9e'
      : '#0085ff'};
  }
`
