import styled from '@emotion/styled'
import {
  FocusBlockIllustration
} from '../FocusSection/styles'
import ListTitle from '../ListTitle'

const TaskTitleWrapper = styled.div`
  padding: 1.5rem 0;
`

const WorkMockupWrapper = styled.img`
  display: block;
  width: 288px;
  align-self: center;

  @media screen and (min-width: 37.5rem)
  {
    width: 364px;
  }

  @media screen and (min-width: 65rem)
  {
    width: 533px;
  }
`

const ImageBlockWrapper = styled(FocusBlockIllustration)`
  display: flex;
  justify-content: center;
  height: auto;
  padding: 1.5rem 0;

  @media screen and (min-width: 52rem)
  {
    padding: 0;
  }
`

const RoleCaption = styled(ListTitle)`
  font-weight: 400;
  font-style: italic;
  font-size: 1.25rem;
  color: #4a4a4a;
`

export {
  TaskTitleWrapper,
  ImageBlockWrapper,
  WorkMockupWrapper,
  RoleCaption
}
