import styled from '@emotion/styled'
import Paragraph from '../Paragraph'

const LogosContainer = styled.div`
  width: 100%;
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  padding: 3rem 0 4rem 0;
`

const LogosCaption = styled(Paragraph)`
  color: #0c4887;
  align-self: center;
`

const Logos = styled.div`
  width: 100%;
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  align-items: center;

  @media screen and (min-width: 52rem)
  {
    justify-content: space-between;
    flex-flow: row wrap;
  }
`

const LogoWrapper = styled.div`
  padding: 1rem;
  width: auto;
  display: flex;
  justify-content: center;
  align-items: center;

  @media screen and (min-width: 45rem)
  {
    width: 33.333%;
  }

  @media screen and (min-width: 65rem)
  {
    width: auto;
  }
`

export {
  LogosContainer,
  LogosCaption,
  Logos,
  LogoWrapper
}
