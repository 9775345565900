import React from 'react'
import { useLocalRemarkForm } from 'gatsby-tinacms-remark'
import { useStaticQuery, graphql } from 'gatsby'

import SecondaryTitle from '../SecondaryTitle'
import TertiaryTitle from '../TertiaryTitle'
import InlineBadge from '../InlineBadge'
import renderAst from '../../utils/renderAst'
import FeaturedWorkForm from './form'

import {
  FocusBlockLayout,
  FocusBlockContents
} from '../FocusSection/styles'
import {
  TaskTitleWrapper,
  WorkMockupWrapper,
  ImageBlockWrapper,
  RoleCaption
} from './styles'

const WorkSection = () => {
  // get the data
  const data = useStaticQuery(graphql`
    query FeaturedWorkQuery {
      markdownRemark(frontmatter: {blockId: {eq: "featured-work"}}) {
        ...TinaRemark
        frontmatter {
          badgeLabel
          title
          subHeading
          role
        }
        htmlAst
      }
    }
  `)
  // tina form
  const [markdownRemark] = useLocalRemarkForm(data.markdownRemark, FeaturedWorkForm)
  return (
    <FocusBlockLayout>
      <FocusBlockContents>
        <SecondaryTitle>
          {markdownRemark.frontmatter.title}
        </SecondaryTitle>
        <TaskTitleWrapper>
          <InlineBadge bg='#fff500' color='#000'>
            {markdownRemark.frontmatter.badgeLabel}
          </InlineBadge>
          <TertiaryTitle>
            {markdownRemark.frontmatter.subHeading}
          </TertiaryTitle>
          <RoleCaption>{markdownRemark.frontmatter.role}</RoleCaption>
        </TaskTitleWrapper>
        {renderAst(markdownRemark.htmlAst)}
      </FocusBlockContents>
      <ImageBlockWrapper>
        <WorkMockupWrapper
          alt=''
          src='images/jovoto-mockup-x1.png'
          srcSet='images/jovoto-mockup-x1.png, images/jovoto-mockup-x2.png 2x, images/jovoto-mockup-x3.png 3x'
        />
      </ImageBlockWrapper>
    </FocusBlockLayout>
  )
}

export default WorkSection
