import React from 'react'
import { useLocalRemarkForm } from 'gatsby-tinacms-remark'
import { useStaticQuery, graphql } from 'gatsby'

import SecondaryTitle from '../SecondaryTitle'
import InlineBadge from '../InlineBadge'
import Paragraph from '../Paragraph'
import ListContainer from '../ListContainer'
import ListTitle from '../ListTitle'
import renderAst from '../../utils/renderAst'
import FocusBlockForm from './form'

import {
  FocusBlockLayout,
  FocusBlockContents,
  FocusBlockIllustration,
  FocusBlockImage,
  FocusSubHeading
} from '../FocusSection/styles'

const PLGBlock = () => {
  // get the data
  const data = useStaticQuery(graphql`
    query FocusGrowthQuery {
      markdownRemark(frontmatter: {blockId: {eq: "focus-growth"}}) {
        ...TinaRemark
        frontmatter {
          badgeLabel
          title
          subHeading
          bodyText
          listTitle
        }
        htmlAst
      }
    }
  `)
  // tina form
  const [markdownRemark] = useLocalRemarkForm(data.markdownRemark, FocusBlockForm)
  return (
    <FocusBlockLayout>
      <FocusBlockIllustration>
        <FocusBlockImage
          alt='Human-centered design'
          src='images/human-centered-design.png'
          srcSet='images/human-centered-design.png, images/human-centered-design-x2.png 2x, images/human-centered-design-x3.png 3x'
        />
      </FocusBlockIllustration>
      <FocusBlockContents>
        <div>
          <InlineBadge>
            {markdownRemark.frontmatter.badgeLabel}
          </InlineBadge>
        </div>
        <SecondaryTitle>
          {markdownRemark.frontmatter.title}
        </SecondaryTitle>
        <FocusSubHeading>{markdownRemark.frontmatter.subHeading}</FocusSubHeading>
        <Paragraph>{markdownRemark.frontmatter.bodyText}</Paragraph>
        <ListContainer>
          <ListTitle>{markdownRemark.frontmatter.listTitle}</ListTitle>
          {renderAst(markdownRemark.htmlAst)}
        </ListContainer>
      </FocusBlockContents>
    </FocusBlockLayout>
  )
}

export default PLGBlock
