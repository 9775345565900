import styled from '@emotion/styled'

export default styled.nav`
  border-bottom: 1px solid #eee;
  background-color: #fff;
  width: 100%;
  top:
    ${props => props.inView
    ? 'auto'
    : 0};
  left:
    ${props => props.inView
    ? 'auto'
    : 0};
  position:
    ${props => props.inView
    ? 'static'
    : 'fixed'};
  z-index:
    ${props => props.inView
    ? 'auto'
    : 5};
`
