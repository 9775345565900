const ServicesBlockForm = {
  fields: [
    {
      label: 'Block title',
      name: 'frontmatter.title',
      component: 'text'
    },
    {
      label: 'Badge label',
      name: 'frontmatter.badgeLabel',
      component: 'text'
    },
    {
      label: 'Body text',
      name: 'frontmatter.bodyText',
      component: 'textarea'
    },
    {
      label: 'Feature list title',
      name: 'frontmatter.listTitle',
      component: 'text'
    },
    {
      label: 'Feature list items',
      name: 'rawMarkdownBody',
      component: 'markdown'
    },
    {
      label: 'Summary title',
      name: 'frontmatter.summaryTitle',
      component: 'text'
    },
    {
      label: 'Summary body text',
      name: 'frontmatter.summaryBody',
      component: 'textarea'
    },
    {
      label: 'Get in touch',
      name: 'frontmatter.buttonLabel',
      component: 'text'
    }
  ]
}

export default ServicesBlockForm
