import React from 'react'

import PLGBlock from '../PLGBlock'
import PLCBlock from '../PLCBlock'
import CCBlock from '../CCBlock'

const FocusSection = () => (
  <React.Fragment>
    <CCBlock />
    <PLCBlock />
    <PLGBlock />
  </React.Fragment>
)

export default FocusSection
