import React from 'react'
import { useLocalRemarkForm } from 'gatsby-tinacms-remark'
import { useStaticQuery, graphql } from 'gatsby'

import AboutSectionForm from './form'
import renderAst from '../../utils/renderAst'

import {
  AboutContainer,
  AboutImageContainer,
  AboutImage,
  AboutTitle,
  AboutContents,
  AboutClaim
} from './styles'

const AboutSection = () => {
  // get the data
  const data = useStaticQuery(graphql`
    query AboutSectionQuery {
      markdownRemark(frontmatter: {blockId: {eq: "about-block"}}) {
        ...TinaRemark
        frontmatter {
          title
          claim
        }
        htmlAst
      }
    }
  `)
  // tina form
  const [markdownRemark] = useLocalRemarkForm(data.markdownRemark, AboutSectionForm)
  return (
    <React.Fragment>
      <AboutContainer>
        <AboutImageContainer>
          <AboutImage
            alt=''
            src='images/anne-triangle.png'
            srcSet='images/anne-triangle.png, images/anne-triangle-x2.png 2x, images/anne-triangle-x3.png 3x'
          />
        </AboutImageContainer>
        <AboutTitle>
          {markdownRemark.frontmatter.title}
        </AboutTitle>
        <AboutContents>
          {renderAst(markdownRemark.htmlAst)}
        </AboutContents>
        <AboutClaim>
          {markdownRemark.frontmatter.claim}
        </AboutClaim>
      </AboutContainer>
    </React.Fragment>
  )
}

export default AboutSection
