import React from 'react'

const ChallengeIcon = () => {
  return (
    <svg width='160' height='140' viewBox='0 0 160 140' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path d='M34 72.625L45.6205 84.2876' stroke='#fff' strokeWidth='1.5' strokeMiterlimit='10' strokeLinecap='square' />
      <path d='M34 84.2876L45.6205 72.625' stroke='#fff' strokeWidth='1.5' strokeMiterlimit='10' strokeLinecap='square' />
      <path d='M48.0981 16.6494L57.0051 25.5887' stroke='#fff' strokeWidth='1.5' strokeMiterlimit='10' strokeLinecap='square' />
      <path d='M48.0981 25.5887L57.0051 16.6494' stroke='#fff' strokeWidth='1.5' strokeMiterlimit='10' strokeLinecap='square' />
      <path d='M116.895 64.0903L126.743 73.9743' stroke='#fff' strokeWidth='1.5' strokeMiterlimit='10' strokeLinecap='square' />
      <path d='M116.895 73.9743L126.743 64.0903' stroke='#fff' strokeWidth='1.5' strokeMiterlimit='10' strokeLinecap='square' />
      <path d='M75.4355 85.8125L92.9445 103.385' stroke='#fff' strokeWidth='1.5' strokeMiterlimit='10' strokeLinecap='square' />
      <path d='M75.4355 103.385L92.9445 85.8125' stroke='#fff' strokeWidth='1.5' strokeMiterlimit='10' strokeLinecap='square' />
      <path d='M76.8521 43.3535L90.211 56.761' stroke='#fff' strokeWidth='1.5' strokeMiterlimit='10' strokeLinecap='square' />
      <path d='M76.8521 56.761L90.211 43.3535' stroke='#fff' strokeWidth='1.5' strokeMiterlimit='10' strokeLinecap='square' />
      <path d='M48.0158 127.23C54.0543 127.23 58.9495 122.317 58.9495 116.256C58.9495 110.196 54.0543 105.283 48.0158 105.283C41.9772 105.283 37.082 110.196 37.082 116.256C37.082 122.317 41.9772 127.23 48.0158 127.23Z' stroke='#fff' strokeWidth='1.5' strokeMiterlimit='10' strokeLinecap='square' />
      <circle cx='47.9924' cy='116.193' r='3.32057' fill='#fff' />
      <path d='M60.0619 116.723H84.4504C101.809 116.723 108.405 102.96 108.405 94.4239C108.405 85.4519 101.462 72.5383 83.4089 72.5383H78.809C66.1808 72.5383 58.4558 63.6281 57.2412 55.0951C55.8091 45.0343 62.5452 37.4451 72.343 30.531C80.675 24.6513 93.5636 14.9824 93.5636 14.9824' stroke='#fff' strokeWidth='1.5' strokeMiterlimit='10' strokeLinecap='square' />
      <path d='M79.5034 12H96.0441' stroke='#fff' strokeWidth='1.5' strokeMiterlimit='10' strokeLinecap='square' />
      <path d='M96.0439 12V28.6007' stroke='#fff' strokeWidth='1.5' strokeMiterlimit='10' strokeLinecap='square' />
    </svg>
  )
}

export default ChallengeIcon
