import React from 'react'
import { useLocalRemarkForm } from 'gatsby-tinacms-remark'
import { useStaticQuery, graphql } from 'gatsby'

import Button from '../Button'
import CharacterCircle from '../CharacterCircle'
import StoryIcon from '../StoryIcon'
import ChallengeIcon from '../ChallengeIcon'
import MissionIcon from '../MissionIcon'
import ContactSectionForm from './form'
import renderAst from '../../utils/renderAst'

import {
  ContactContainer,
  ContactStep,
  ActionStep,
  CenteredSecondaryTitle,
  CenteredTertiaryTitle,
  StepTitle,
  CenteredParagraph,
  CenteredParagraphStep,
  AvailabilityNote,
  ContactButtonContainer
} from './styles'

const ServicesSection = () => {
  // get the data
  const data = useStaticQuery(graphql`
    query ContactSectionQuery {
      markdownRemark(frontmatter: {blockId: {eq: "contact-block"}}) {
        ...TinaRemark
        frontmatter {
          title
          subTitle
          stepOneTitle
          stepOneDuration
          stepOneDescription
          stepTwoTitle
          stepTwoDuration
          stepTwoDescription
          stepThreeTitle
          stepThreeDuration
          stepThreeDescription
          contactActionTitle
          availability
          buttonLabel
        }
        htmlAst
      }
    }
  `)
  // tina form
  const [markdownRemark] = useLocalRemarkForm(data.markdownRemark, ContactSectionForm)
  return (
    <React.Fragment>
      <ContactContainer>
        <CenteredSecondaryTitle>
          {markdownRemark.frontmatter.title}
        </CenteredSecondaryTitle>
        <CenteredParagraph>
          {markdownRemark.frontmatter.subTitle}
        </CenteredParagraph>
        <ContactStep>
          <StoryIcon />
          <StepTitle>
            {markdownRemark.frontmatter.stepOneTitle}
          </StepTitle>
          <CenteredParagraph>
            {markdownRemark.frontmatter.stepOneDuration}
          </CenteredParagraph>
          <CenteredParagraphStep>
            {markdownRemark.frontmatter.stepOneDescription}
          </CenteredParagraphStep>
        </ContactStep>
        <ActionStep><CharacterCircle>+</CharacterCircle></ActionStep>
        <ContactStep>
          <ChallengeIcon />
          <StepTitle>
            {markdownRemark.frontmatter.stepTwoTitle}
          </StepTitle>
          <CenteredParagraph>
            {markdownRemark.frontmatter.stepTwoDuration}
          </CenteredParagraph>
          <CenteredParagraphStep>
            {markdownRemark.frontmatter.stepTwoDescription}
          </CenteredParagraphStep>
        </ContactStep>
        <ActionStep><CharacterCircle>=</CharacterCircle></ActionStep>
        <ContactStep>
          <MissionIcon />
          <StepTitle>
            {markdownRemark.frontmatter.stepThreeTitle}
          </StepTitle>
          <CenteredParagraph>
            {markdownRemark.frontmatter.stepThreeDuration}
          </CenteredParagraph>
          <CenteredParagraphStep>
            {markdownRemark.frontmatter.stepThreeDescription}
          </CenteredParagraphStep>
        </ContactStep>
        <CenteredTertiaryTitle>
          {markdownRemark.frontmatter.contactActionTitle}
        </CenteredTertiaryTitle>
        <AvailabilityNote>
          {markdownRemark.frontmatter.availability}
        </AvailabilityNote>
        <ContactButtonContainer>
          <div>
            <Button
              alternate
              renderAs='a'
              href='https://calendly.com/pathberlin/55-min-intro-session'
              noTarget
            >
              {markdownRemark.frontmatter.buttonLabel}
            </Button>
          </div>
        </ContactButtonContainer>
        {renderAst(markdownRemark.htmlAst)}
      </ContactContainer>
    </React.Fragment>
  )
}

export default ServicesSection
