import React from 'react'
import { useLocalRemarkForm } from 'gatsby-tinacms-remark'
import { useStaticQuery, graphql } from 'gatsby'

import ServicesTitlesForm from './form'
import InlineBadge from '../InlineBadge'
import PLGLogosBlock from '../PLGLogosBlock'
import Paragraph from '../Paragraph'
import ProductConsultingTile from '../ProductConsultingTile'
import ProductOwnerTile from '../ProductOwnerTile'
import PLCTrainingTile from '../PLCTrainingTile'
import PLGTrainingTile from '../PLGTrainingTile'

import {
  ServicesContainer,
  ServicesTitle,
  ServicesTertiaryTitle,
  ServicesBadge,
  TrainingsContainer,
  FullWidthWrapper
} from './styles'

const ServicesSection = () => {
  // get the data
  const data = useStaticQuery(graphql`
    query ServicesTitlesQuery {
      markdownRemark(frontmatter: {blockId: {eq: "services-titles"}}) {
        ...TinaRemark
        frontmatter {
          title
          badgeLabel
          trainingsTitle
          trainingsDuration
          trainingsDescription
          trainingsButtonLabel
          plgLogosDescription
        }
      }
    }
  `)
  // tina form
  const [markdownRemark] = useLocalRemarkForm(data.markdownRemark, ServicesTitlesForm)
  return (
    <React.Fragment>
      <ServicesContainer>
        <ServicesBadge>
          <InlineBadge bg='#fff500' color='#000'>
            {markdownRemark.frontmatter.badgeLabel}
          </InlineBadge>
        </ServicesBadge>
        <ServicesTitle>
          {markdownRemark.frontmatter.title}
        </ServicesTitle>
        <ProductConsultingTile />
        <ProductOwnerTile />
        <TrainingsContainer>
          <FullWidthWrapper>
            <ServicesTertiaryTitle>
              {markdownRemark.frontmatter.trainingsTitle}
            </ServicesTertiaryTitle>
          </FullWidthWrapper>
          <div>
            <InlineBadge bg='#fff' color='#0085ff'>
              {markdownRemark.frontmatter.trainingsDuration}
            </InlineBadge>
          </div>
          <FullWidthWrapper>
            <Paragraph>
              {markdownRemark.frontmatter.trainingsDescription}
            </Paragraph>
          </FullWidthWrapper>
          <PLCTrainingTile />
          <PLGTrainingTile />
          {/*
          <TileButtonContainer>
            <Button primary>
              {markdownRemark.frontmatter.trainingsButtonLabel}
            </Button>
          </TileButtonContainer>
          */}
        </TrainingsContainer>
      </ServicesContainer>
      <PLGLogosBlock logosCaption={markdownRemark.frontmatter.plgLogosDescription} />
    </React.Fragment>
  )
}

export default ServicesSection
