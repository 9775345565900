import styled from '@emotion/styled'
import SubHeading from '../SubHeading'

const FocusBlockLayout = styled.div`
  width: 100%;
  display: flex;
  flex-flow: column nowrap;
  padding: 2rem 0 3rem 0;

  @media screen and (min-width: 52rem)
  {
    flex-flow: row nowrap;
    padding: 6.5rem 0;
  }
`

const FocusBlockContents = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  flex-flow: column nowrap;
  order: 3;

  @media screen and (min-width: 52rem)
  {
    width: 50%;
    order: 0;
  }
`

const FocusBlockIllustration = styled.div`
  padding: 1.5rem;
  width: 100%;
  order: 2;
  display: flex;
  justify-content: center;
  align-items: center;

  @media screen and (min-width: 30rem)
  {
    padding: 2rem;
  }

  @media screen and (min-width: 52rem)
  {
    width: 50%;
    order: 0;
  }
`

const FocusBlockImage = styled.img`
  max-width: 100%;
  height: auto;
`

const FocusSubHeading = styled(SubHeading)`
  font-weight: 700;
`

export {
  FocusBlockLayout,
  FocusBlockContents,
  FocusBlockIllustration,
  FocusBlockImage,
  FocusSubHeading
}
