import styled from '@emotion/styled'

const CharCircle = styled.div`
  flex: 0 0 auto;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  border: 0.125rem solid #0d5299;
  width: 3.5rem;
  height: 3.5rem;
`

const Char = styled.span`
  font-size: 2.5rem;
  color: #0d5299;
  padding: 0 0 0.375rem 0;
`

export {
  CharCircle,
  Char
}
