import React, { useRef } from 'react'
import { useInView } from 'react-intersection-observer'
import HomeHeader from '../components/HomeHeader'
import HomeNavContainer from '../components/HomeNavContainer'
import HomeNavigation from '../components/HomeNavigation'
import AboutSection from '../components/AboutSection'
import FocusSection from '../components/FocusSection'
import WorkSection from '../components/WorkSection'
import ServicesSection from '../components/ServicesSection'
import ContactSection from '../components/ContactSection'
import Seo from '../components/SEO'
import Footer from '../components/Footer'
import Layout from '../components/Layout'
import LayoutWithBackground from '../components/LayoutWithBackground'

const Home = () => {
  const sectionRefs = [
    useRef(null),
    useRef(null),
    useRef(null),
    useRef(null),
    useRef(null)
  ]
  // intersection-observer for nav
  const [navRef, inView, entry] = useInView({ threshold: 1 })
  return (
    <React.Fragment>
      <Seo />
      <HomeHeader />
      <HomeNavContainer ref={navRef}>
        <HomeNavigation
          sectionRefs={sectionRefs}
          inView={entry && entry.boundingClientRect.top < 0 ? inView : true}
        />
      </HomeNavContainer>
      <LayoutWithBackground bgColor='#f3f3f3' noTop>
        <Layout id='about' ref={sectionRefs[0]}>
          <AboutSection />
        </Layout>
      </LayoutWithBackground>
      <Layout id='focus' ref={sectionRefs[1]}>
        <FocusSection />
      </Layout>
      <LayoutWithBackground>
        <Layout id='services' ref={sectionRefs[2]}>
          <ServicesSection />
        </Layout>
      </LayoutWithBackground>
      <Layout id='work' ref={sectionRefs[3]}>
        <WorkSection />
      </Layout>
      <LayoutWithBackground>
        <Layout id='contact' ref={sectionRefs[4]}>
          <ContactSection />
        </Layout>
      </LayoutWithBackground>
      <Footer />
    </React.Fragment>
  )
}

export default Home
