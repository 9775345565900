import React, { useState } from 'react'
import PropTypes from 'prop-types'
import useScrollSpy from '../UseSpyScroll'

import NavLayout from './styles'
import Layout from '../Layout'
import TabbedNavigation from '../TabbedNavigation'

const tabs = [
  {
    name: 'About',
    to: 'about'
  },
  {
    name: 'Focus',
    to: 'focus'
  },
  {
    name: 'Services',
    to: 'services'
  },
  {
    name: 'Work',
    to: 'work'
  },
  {
    name: 'Contact',
    to: 'contact'
  }
]

const HomeNavigation = ({ sectionRefs, inView }) => {
  const activeSection = useScrollSpy({
    sectionElementRefs: sectionRefs,
    offsetPx: -64
  })
  let isTouchingTimer
  const [isTouching, setIsTouching] = useState(false)
  const [canTouchThis, setCanTouchThis] = useState(false)
  const hover = () => setIsTouching(false)
  const touch = () => setIsTouching(true)
  const removeClass = () => setCanTouchThis(false)
  const addClass = () => setCanTouchThis(true)
  const handleMouseOver = () => {
    if (!isTouching && canTouchThis) {
      hover()
      removeClass()
    }
  }

  const handleTouchStart = () => {
    clearInterval(isTouchingTimer)
    touch()
    if (!canTouchThis) {
      addClass()
    }
    isTouchingTimer = setTimeout(() => hover(), 1000)
  }
  return (
    <NavLayout inView={inView}>
      <Layout>
        <TabbedNavigation
          onTouchStart={handleTouchStart}
          onMouseOver={handleMouseOver}
          activeSection={activeSection}
          tabs={tabs}
        />
      </Layout>
    </NavLayout>
  )
}

HomeNavigation.propTypes = {
  sectionRefs: PropTypes.array,
  inView: PropTypes.bool
}

export default HomeNavigation
