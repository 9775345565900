import styled from '@emotion/styled'
import { css } from '@emotion/core'

const containerEdgeTop = '<svg xmlns="http://www.w3.org/2000/svg" width="1200" height="14" viewBox="0 0 1200 14" fill="#fff"><path d="M32.248,4.17587c18.91532.52959,37.823,1.35145,56.74081,1.74345,12.63484.26183,25.28516-.21187,37.92173.0079,13.08147.22747,26.16712.42149,39.22944,1.15055V7.092c.05057-.00285.10115-.00427.15173-.00712.05058.00285.10116.00427.15174.00712V7.07777c13.06238-.72906,26.148-.92308,39.22944-1.15055,12.63663-.21977,25.287.25393,37.92186-.0079,18.91771-.392,37.82548-1.21386,56.74074-1.74345,7.43374-.20814,18.2746.80964,25.71086.68456,62.77721,1.22633,122.16185.70676,184.76713,4.89343a43.90325,43.90325,0,0,1,6.72163,1.6668c15.15444.82269,47.474-1.31882,88.096-.7277,11.51645.16761,25.0656,3.15085,37.40832,3.30512,7.404.09249,11.41451-3.182,18.96064-3.09363,10.44771.1224,28.71843,1.6171,39.17258,1.73268,42.82624.47353,79.10315.82476,108.82742,1.24126,15.23939.21354,27.42643-4.182,35-3.96525,7.38107,0,19.98533,4.14438,27,3.96525,8.09987-.20677,15.90087-2.7127,24-2.97394,13.8104-.44552,24.13147,2.11689,38,.99132,16.678-1.35371,32.48917,2.50493,49.18206,1.30815,2.44108-.175,4.81974-1.16675,7.22657-1.78326a43.90325,43.90325,0,0,1,6.72163-1.6668c6.24935-.56733,12.55045-.63443,18.788-1.28542,19.00764-1.98363,38.09471-2.20944,57.17122-2.54123,12.63663-.21976,25.287.25394,37.92186-.00789,18.91771-.392,37.82548-1.21386,56.74074-1.74345,10.74652-.30093,21.50108-.34,32.24791-.62322V0H0V3.55265C10.747,3.8359,21.50151,3.87494,32.248,4.17587Z"/></svg>'
const containerEdgeBottom = '<svg xmlns="http://www.w3.org/2000/svg" width="1200" height="17" viewBox="0 0 1200 17" fill="#fff"><path d="M1168.2266,13.57767c-18.91532-.53423-37.823-1.36329-56.7408-1.75873-12.63485-.26412-25.28516.21373-37.92173-.008-13.08147-.22947-26.16712-.42519-39.22944-1.16064V10.636c-.05058.00287-.10116.00431-.15174.00718-.05058-.00287-.10116-.00431-.15174-.00718v.01436c-13.06238.73545-26.148.93117-39.22943,1.16064-12.63663.22169-25.287-.25616-37.92186.008-18.91771.39544-37.82548,1.2245-56.74074,1.75873-7.43374.21-18.27461-.81673-25.71086-.69056C811.651,11.65,752.26641,12.17416,689.66113,7.95081A43.59125,43.59125,0,0,1,682.9395,6.2694c-15.15444-.8299-47.474,1.33038-88.096.73408C583.32709,6.8344,569.77794,3.825,557.43521,3.6694c-7.404-.09331-14.90823-.18446-22.45436-.27358-10.44771-.12348-25.2247,1.763-35.67885,1.64645C456.47575,4.5646,419.14035,2.1326,389.41609,1.71244c-15.2394-.21541-27.36139-.42932-34.935-.64795-7.38107,0-14.40452-.14311-21.4192.03758-8.09986.20859-16.189.78974-24.28812,1.05327-13.81039.44942-27.51772-2.21666-41.38625-1.08122-16.678,1.36557-33.40216,2.18913-50.095,3.39639-2.44108.17656-4.81974,1.177-7.22657,1.79889a43.59153,43.59153,0,0,1-6.72163,1.68141c-6.24935.57229-12.55045.64-18.788,1.29668-19.00764,2.001-38.09471,2.2288-57.17123,2.56349-12.63662.22169-25.287-.25616-37.92185.008-18.91772.39544-37.82549,1.2245-56.74074,1.75873-10.74652.30357-21.50109.343-32.24791.62868v3.58378h1200V14.20635C1189.72763,13.92062,1178.97306,13.88124,1168.2266,13.57767Z"/></svg>'

export default styled.div`
  background-color:
    ${props => props.bgColor
    ? props.bgColor
    : '#0085ff'};
  position: relative;

  ::before,
  ::after
  {
    content: "";
    display: block;
    left: 0;
    position: absolute;
    width: 100%;
  }

  ::before
  {
    height: 0.875rem;
    top: -0.125rem;
    background-image:
      ${props => props.noTop
      ? 'none'
      : css`url("data:image/svg+xml;charset=utf-8,${encodeURIComponent(containerEdgeTop)}") `};
    background-size:
      ${props => props.noTop
      ? 'auto'
      : '1200px 14px'};
    background-repeat: repeat-x;
    background-position: left top;
  }

  ::after
  {
    bottom: -0.125rem;
    height: 1.0625rem;
    background-image: url("data:image/svg+xml;charset=utf-8,${encodeURIComponent(containerEdgeBottom)}");
    background-size: 1200px 17px;
    background-repeat: repeat-x;
    background-position: left bottom;
  }
`
