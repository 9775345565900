const ContactSectionForm = {
  label: 'Contact section',
  fields: [
    {
      label: 'Title',
      name: 'frontmatter.title',
      component: 'text'
    },
    {
      label: 'Subtitle',
      name: 'frontmatter.subTitle',
      component: 'textarea'
    },
    {
      label: 'First step title',
      name: 'frontmatter.stepOneTitle',
      component: 'text'
    },
    {
      label: 'First step duration',
      name: 'frontmatter.stepOneDuration',
      component: 'text'
    },
    {
      label: 'First step description',
      name: 'frontmatter.stepOneDescription',
      component: 'textarea'
    },
    {
      label: 'Second step title',
      name: 'frontmatter.stepTwoTitle',
      component: 'text'
    },
    {
      label: 'Second step duration',
      name: 'frontmatter.stepTwoDuration',
      component: 'text'
    },
    {
      label: 'Second step description',
      name: 'frontmatter.stepTwoDescription',
      component: 'textarea'
    },
    {
      label: 'Third step title',
      name: 'frontmatter.stepThreeTitle',
      component: 'text'
    },
    {
      label: 'Third step duration',
      name: 'frontmatter.stepThreeDuration',
      component: 'text'
    },
    {
      label: 'Third step description',
      name: 'frontmatter.stepThreeDescription',
      component: 'textarea'
    },
    {
      label: 'Actionable contact title',
      name: 'frontmatter.contactActionTitle',
      component: 'text'
    },
    {
      label: 'Availability note',
      name: 'frontmatter.availability',
      component: 'textarea'
    },
    {
      label: 'Contact button label',
      name: 'frontmatter.buttonLabel',
      component: 'text'
    },
    {
      label: 'Alternative contact text',
      name: 'rawMarkdownBody',
      component: 'markdown'
    }
  ]
}

export default ContactSectionForm
