import styled from '@emotion/styled'

export default styled.span`
  display: inline-block;
  font-size: 0.75rem;
  line-height: normal;
  font-weight: 700;
  text-transform: uppercase;
  border-radius: 1rem;
  padding: 0.125rem 0.625rem;
  flex: 0 0 auto;
  white-space: nowrap;
  background-color: ${props => props.bg};
  color: ${props => props.color};
`
