import React from 'react'
import { useLocalRemarkForm } from 'gatsby-tinacms-remark'
import { useStaticQuery, graphql } from 'gatsby'

import InlineBadge from '../InlineBadge'
import Paragraph from '../Paragraph'

import TrainingsBlockForm from '../PLCTrainingTile/form'
import {
  TrainingTile,
  ServicesQuaternaryTitle
} from '../ServicesSection/styles'

const PLGTrainingTile = () => {
  // get the data
  const data = useStaticQuery(graphql`
    query ServicesTrainingPLGQuery {
      markdownRemark(frontmatter: {blockId: {eq: "services-training-plg"}}) {
        ...TinaRemark
        frontmatter {
          title
          badgeLabel
          bodyText
        }
      }
    }
  `)
  // tina form
  const [markdownRemark] = useLocalRemarkForm(data.markdownRemark, TrainingsBlockForm)
  return (
    <TrainingTile>
      <div>
        <InlineBadge bg='#fff500' color='#000'>
          {markdownRemark.frontmatter.badgeLabel}
        </InlineBadge>
      </div>
      <ServicesQuaternaryTitle>
        {markdownRemark.frontmatter.title}
      </ServicesQuaternaryTitle>
      <Paragraph>
        {markdownRemark.frontmatter.bodyText}
      </Paragraph>
    </TrainingTile>
  )
}

export default PLGTrainingTile
