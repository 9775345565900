import styled from '@emotion/styled'
import SecondaryTitle from '../SecondaryTitle'
import TertiaryTitle from '../TertiaryTitle'

const AboutContainer = styled.div`
  width: 100%;
  display: flex;
  flex-flow: column nowrap;
  padding: 2rem 0 4rem 0;
  margin: 0;

  @media screen and (min-width: 52rem)
  {
    width: 66.666%;
    margin: 0 auto;
    padding: 2.5rem 0 6rem 0;
  }
`

const AboutImageContainer = styled.div`
  display: flex;
  justify-content: center;
  margin: 0 0 1.625rem 0;
`

const AboutImage = styled.img`
  max-width: 100%;
  height: auto;
  align-self: center;
`

const AboutContents = styled.div`
  width: 100%;
`

const AboutTitle = styled(SecondaryTitle)`
  width: 100%;
  text-align: center;
`

const AboutClaim = styled(TertiaryTitle)`
  color: #0085ff;
  font-weight: 400;
  font-style: italic;
  width: 100%;
  text-align: center;
`

export {
  AboutContainer,
  AboutImageContainer,
  AboutImage,
  AboutTitle,
  AboutContents,
  AboutClaim
}
