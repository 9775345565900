const ServicesTitlesForm = {
  label: 'Services—Trainings titles',
  fields: [
    {
      label: 'Badge label',
      name: 'frontmatter.badgeLabel',
      component: 'text'
    },
    {
      label: 'Services title',
      name: 'frontmatter.title',
      component: 'text'
    },
    {
      label: 'Trainings title',
      name: 'frontmatter.trainingsTitle',
      component: 'text'
    },
    {
      label: 'Trainings duration badge label',
      name: 'frontmatter.trainingsDuration',
      component: 'text'
    },
    {
      label: 'Trainings description',
      name: 'frontmatter.trainingsDescription',
      component: 'textarea'
    },
    {
      label: 'Trainings button label',
      name: 'frontmatter.trainingsButtonLabel',
      component: 'text'
    },
    {
      label: 'A line above product-led growth logos',
      name: 'frontmatter.plgLogosDescription',
      component: 'textarea'
    }
  ]
}

export default ServicesTitlesForm
