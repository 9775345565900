import React from 'react'

const MissionIcon = () => {
  return (
    <svg width='160' height='140' viewBox='0 0 160 140' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path d='M60.1599 83.3973L46.2202 134.117L54.1496 134.729L55.3133 130.874L63.7986 136.346L71.2493 136.959C71.2493 136.959 71.5903 135.796 71.8717 134.53C72.2935 132.632 71.639 130.855 69.1892 129.88C66.7394 128.906 61.1592 126.699 61.1592 126.699L75.0793 90.4093L91.737 103.861L83.1284 134.338L90.9762 134.984L92.6377 130.794L101.161 136.95L107.701 137.488C107.701 137.488 108.027 136.716 108.431 135.585C109.134 133.616 108.955 131.352 106.111 130.312C103.267 129.272 97.9257 127.197 97.9257 127.197C97.9257 127.197 102.408 110.146 103.541 107.285C104.673 104.424 106.251 100.474 101.455 95.5588C96.6584 90.6436 86.3861 78.7358 86.3861 78.7358' stroke='#fff' strokeWidth='1.5' strokeLinecap='square' strokeLinejoin='round' />
      <path d='M72.4507 78.6242L89.5138 77.3004L87.3022 47.0199L103.843 38.0344L116.907 20.3575L109.561 11.9805L97.4745 28.181L82.2784 37.2008L78.4998 37.4888L76.9998 32.9888C76.9998 32.9888 77.9998 32.9888 78.9998 32.9888C80.9998 32.9888 82.1971 30.966 81.644 29.3527C79.9567 24.4313 77.6283 18.6658 77.6283 18.6658' stroke='#fff' strokeWidth='1.5' strokeLinecap='square' strokeLinejoin='round' />
      <path d='M61.1872 71.772L62.2396 51.007L47.3097 60.0958L61.1872 71.772ZM61.1872 71.772L60.0249 82.7516C60.0249 82.7516 38.5978 64.6687 37.0372 63.2011C35.4767 61.7334 35.563 57.8775 38.0802 55.6681C41.1479 52.9755 58.53 39.1238 62.0992 38.7738C62.5372 38.7308 63.1716 38.6746 63.9479 38.6094' stroke='#fff' strokeWidth='1.5' strokeLinecap='square' strokeLinejoin='round' />
      <path d='M57.8657 32.6622C60.0051 31.8599 61.0747 29.5869 60.6694 26.7242C60.2476 23.7449 58.8121 18.9563 61.8744 15.1117C66.3245 9.52484 75.4822 12.3605 77.6521 13.1178C81.1967 14.3547 80.3057 18.1288 77.2818 18.5024C72.6885 19.0698 71.198 20.5003 71.198 20.5003L72.2453 23.9599L68.684 24.5673C68.684 24.5673 69.1834 26.0468 70.6487 31.5518C71.6287 35.2338 69.3803 38.0999 66.9737 38.3521L63.6987 38.629' stroke='#fff' strokeWidth='1.5' strokeLinecap='square' strokeLinejoin='round' />
      <path d='M61.5794 72.7213C63.2721 71.4567 64.8713 70.0715 66.3645 68.5766C67.9628 67.1561 71.5435 68.454 70.4072 70.8844C68.4382 75.0961 65.3341 82.53 62.9809 82.2658C60.6276 82.0017 60.0044 81.5742 60.0044 81.5742' stroke='#fff' strokeWidth='1.5' strokeLinecap='square' strokeLinejoin='round' />
      <path d='M110.852 12.7964C110.852 12.7964 111.81 12.0599 113.272 9.41219C114.733 6.76444 116.456 6.76322 116.456 6.76322C116.456 6.76322 118.07 4.87371 120.258 2.58094C120.522 2.26694 120.895 2.06405 121.302 2.01278C121.709 1.96151 122.12 2.06565 122.454 2.30438C122.787 2.54312 123.018 2.89888 123.101 3.30063C123.184 3.70238 123.112 4.12054 122.9 4.47159C121.68 6.34932 120.712 7.63063 120.712 7.63063C121.684 8.41336 122.388 9.47969 122.726 10.6812C123.132 12.3443 122.489 12.5421 122.529 14.0914C122.579 16.052 116.562 19.5757 116.562 19.5757' stroke='#fff' strokeWidth='1.5' strokeLinecap='square' strokeLinejoin='round' />
    </svg>
  )
}

export default MissionIcon
