import React from 'react'

const StoryIcon = () => {
  return (
    <svg width='160' height='140' viewBox='0 0 160 140' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path d='M43.0538 36.0376C43.0538 36.0376 39.4369 43.3488 33.4548 47.5326C17.8591 58.4402 2 53.7036 2 53.7036' stroke='#fff' strokeWidth='1.5' strokeMiterlimit='10' />
      <path d='M90.2121 72.6571C85.291 111.711 66.3326 128.892 51.1896 128.892C32.5519 128.892 16.5941 111.844 12.167 72.6571C7.11934 27.9771 29.638 16.4219 51.1896 16.4219C72.7411 16.4219 95.2598 32.5991 90.2121 72.6571Z' stroke='#fff' strokeWidth='1.5' strokeMiterlimit='10' />
      <path d='M60.0033 48.2767C71.0799 57.2928 86.2744 58.9018 91.4147 59.5521C96.8131 60.235 105.221 57.4475 104.967 66.3015C104.713 75.1555 91.7654 101.93 108.559 118.589C121.769 131.693 141.66 126.388 151.755 123.114C169.509 117.357 141.272 102.123 137.65 74.2232C136.491 65.2971 143.407 54.3611 141.466 37.0283C140.051 24.3936 127.505 8.96421 100.114 11.2218C69.9999 13.7036 62.6441 24.5103 56.8198 33.5618C52.2902 40.6014 49.0542 49.5465 50.9956 63.9905C52.8819 78.0243 40.7061 88.7378 40.7061 88.7378C40.7061 88.7378 46.9999 94.2036 54.9755 94.6117C54.9572 100.767 55.641 106.905 57.014 112.907C58.7267 120.234 67.3194 132.778 85.3587 125.425C95.4993 121.293 100.131 119.44 100.131 119.44' stroke='#fff' strokeWidth='1.5' strokeMiterlimit='10' />
      <path d='M47.5 25.7036C48 29.7036 50.0428 39.2036 58.3686 46.862C58.902 47.3525 59.4468 47.8239 60.0033 48.2761' stroke='#fff' strokeWidth='1.5' strokeMiterlimit='10' />
      <path d='M36.0469 100.839C36.0469 100.839 44.2979 107.098 55.364 105.75C64.5296 104.633 67.8861 98.8164 67.8861 98.8164' stroke='#fff' strokeWidth='1.5' strokeMiterlimit='10' />
      <path d='M38.1479 69.0649C39.561 69.0649 40.7065 67.9286 40.7065 66.5268C40.7065 65.1251 39.561 63.9888 38.1479 63.9888C36.7349 63.9888 35.5894 65.1251 35.5894 66.5268C35.5894 67.9286 36.7349 69.0649 38.1479 69.0649Z' fill='#fff' />
      <path d='M62.5718 69.3821C63.9957 69.3821 65.15 68.2371 65.15 66.8246C65.15 65.4121 63.9957 64.2671 62.5718 64.2671C61.1479 64.2671 59.9937 65.4121 59.9937 66.8246C59.9937 68.2371 61.1479 69.3821 62.5718 69.3821Z' fill='#fff' />
    </svg>
  )
}

export default StoryIcon
