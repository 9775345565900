import styled from '@emotion/styled'

const HeroContainer = styled.div`
  display: flex;
  flex-flow: column nowrap;
  min-height: 0;
  max-height: none;
  height: auto;

  @media screen and (min-width: 52rem)
  {
    flex-flow: row nowrap;
    min-height: 35rem;
    max-height: 40rem;
    height: 68vh;
  }
`

const ContentsContainer = styled.div`
  width: 100%;
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  order: 3;
  padding: 0 0 3rem 0;

  @media screen and (min-width: 23rem)
  {
    padding: 1rem 0 3rem 0;
  }

  @media screen and (min-width: 32rem)
  {
    padding: 1.5rem 0 3rem 0;
  }

  @media screen and (min-width: 52rem)
  {
    order: 2;
    width: 66.666%;
    padding: 0 0 1.5rem 0;
  }

  @media screen and (min-width: 65rem)
  {
    width: 72%;
  }
`

const IllustrationContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  order: 2;

  @media screen and (min-width: 52rem)
  {
    order: 3;
    width: 33.333%;
  }

  @media screen and (min-width: 65rem)
  {
    width: 28%;
  }
`

const ButtonContainer = styled.div`
  padding: 1rem 0 0 0;

  @media screen and (min-width: 52rem)
  {
    padding: 2rem 0 0 0;
  }
`

const SubHeadingContainer = styled.div`
  width: 100%;

  @media screen and (min-width: 65rem)
  {
    width: 75%;
  }
`

export {
  HeroContainer,
  ContentsContainer,
  IllustrationContainer,
  ButtonContainer,
  SubHeadingContainer
}
