const TrainingsBlockForm = {
  fields: [
    {
      label: 'Badge label',
      name: 'frontmatter.badgeLabel',
      component: 'text'
    },
    {
      label: 'Block title',
      name: 'frontmatter.title',
      component: 'text'
    },
    {
      label: 'Body text',
      name: 'frontmatter.bodyText',
      component: 'textarea'
    }
  ]
}

export default TrainingsBlockForm
